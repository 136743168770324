// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/textures/tb-blue-texture-1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".combo-selector{border-top:6px solid gold;background:#019ad4;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:50% auto;background-repeat:repeat}.combo-selector .container{padding-bottom:24px}.combo-selector__input-select{margin:0 auto 25px;position:relative;max-width:450px}.combo-selector__input-select select{width:100%;border:4px solid #01c5b5;margin:0;padding:.5rem 2.25rem .5rem 1rem;font-family:cubano,sans-serif;font-size:1em;line-height:1.5;color:#01c5b5;background-color:#fff;border-radius:.25rem;cursor:pointer;outline:0;-webkit-appearance:none;-moz-appearance:none;appearance:none}.combo-selector__input-select:after{content:\"\";position:absolute;right:0;bottom:0;display:block;width:50px;height:50px;background:#01c5b5 url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 50% no-repeat;background-size:50%;border-top-right-radius:5px;border-bottom-right-radius:5px;pointer-events:none}.combo-selector__pdf-selector{text-align:center}.combo-selector__link{display:inline-block;margin:15px;text-decoration:none;border-bottom:2px solid #67b2e9;padding:10px 0;text-transform:uppercase;color:#fff}.combo-selector__link--disabled{opacity:.7;pointer-events:none}", ""]);
// Exports
module.exports = exports;
