<template >
  <div class="menu-section" :data-index="index" :class="['menu-section--'+themeClass, (index > 3 ? 'menu-section--inactive' : '')]" v-show="show" :id="this.id">
    <div class="menu-section__hero" :class="['menu-section__hero--'+themeClass]">
      <img v-lazy="sectionImage" class="menu-section__hero__image" alt="">
      <h3 class="menu-section__title container" :class="['menu-section__title--'+themeClass]">{{section.fields.displayTitle}}</h3>
    </div>
    <div class="menu-section__content container">
      <div class="menu-section__intro" :class="['menu-section__intro--'+themeClass]" v-if="section.fields.introText" v-html="section.fields.introText"></div>
      <div class="menu-section__additional-info-card" :class="['menu-section__additional-info-card--'+themeClass]" v-if="section.fields.additionalInfoCardBody" >
        <h3 class="menu-section_additional-info-card-title" :class="['menu-section_additional-info-card-title--'+themeClass]"  v-if="section.fields.additionalInfoCardTitle">{{section.fields.additionalInfoCardTitle}}</h3>
          <div class="menu-section_additional-info-card-body" :class="['menu-section_additional-info-card-body--'+themeClass]" v-html="additionalInfoCardBody" ></div>
      </div>
      <!-- <CocktailFilter v-if="section.sys.contentType.sys.id == 'cocktailSection'" /> -->
      <MenuItem :showInitial="showMenuItems" v-for="item in section.fields.menuItems" v-bind:key="item.sys.id" :item="item" :type="section.sys.contentType.sys.id == 'cocktailSection' ? 'cocktail' : 'regular'" :theme="themeClass"/>
    </div>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue"
// import CocktailFilter from "./CocktailFilter.vue"


export default {
  name: 'MenuSection',
  props: {
    section: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  components: {
    MenuItem,
    // CocktailFilter
  },
  computed: {
    sectionImage() {
      return 'https:'+this.section.fields.sectionImage.fields.file.url;
    },
    themeClass () {
      let defaultTheme = 'orange';
      let sectionTheme = this.section.fields.theme;

      return sectionTheme ? sectionTheme.toLowerCase() : defaultTheme;
    },
    additionalInfoCardBody() {
      // return this.section.fields.additionalInfoCardBody ? this.section.fields.additionalInfoCardBody.replace(/£/g, "<span class='currency'><svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 702.44 897.56'><path d='M630.15,658.29H342.49a33.58,33.58,0,0,1-33.58-33.59V598.28a19.73,19.73,0,0,1,19.73-19.74H499.13c22.4,0,33.59-12.32,33.59-33.59V440.46a33.57,33.57,0,0,0-33.58-33.58H328.64a19.74,19.74,0,0,1-19.74-19.74V348.79c0-52.7,5.6-109.52,88.45-109.52,60.46,0,80.61,29.11,86.2,78.37,3.36,23.51,21.28,39.18,44.79,34.71l124.27-28c15.67-3.36,25.75-15.68,24.63-31.35-4.48-86.2-41.43-246.3-279.89-246.3-265.34,0-281,198.16-281,272.05v68.38A19.74,19.74,0,0,1,96.6,406.88H58.7c-21.27,0-33.58,11.19-33.58,33.58V545a33.54,33.54,0,0,0,33.53,33.54H96.6a19.73,19.73,0,0,1,19.74,19.73v219a33.59,33.59,0,0,0,33.59,33.59H630.15a33.58,33.58,0,0,0,33.59-33.58V691.87A33.58,33.58,0,0,0,630.15,658.29Z'/></svg></span>") : false;
      return this.section.fields.additionalInfoCardBody;
    },
    showMenuItems() {
      // this is here so that we do not display menu items anymore on bottomless brunch section, 
      // but it is required for the card still to be rendered, but invisible for the filtering to work
      return !(this.additionalInfoCardBody && this.id == 'bottomlessbrunch');
    }
  },
  created () {
    
  },
  mounted () {
  
  },
  data() {
    return {
      show: true,
    }
  },
  watch: {
    '$store.state.filtersUpdated': function () {
      // check if there are any visible menu items in the section
      // doing this in next tick because we need to wait for the DOM update
      // issued by filtering in the menu item component, since it fires off 
      // the same condition. At the same time show / hide quick links from scrollable nav bar
      this.$nextTick(() => {
        let items = this.$el.querySelectorAll('.menu-item');
        this.show = Boolean(items.length);
        let link = this.$el.closest('.Menu').querySelector(`span[data-item="${this.id.replaceAll(" ", "").toLowerCase()}"]`);
        link.style.display = this.show ? "initial" : "none";
      })
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
  .menu-section {
    background: #F2F2EF;
    background-image: url(../assets/images/textures/general-texture_web.jpg);
    background-size: 100% auto;
    background-repeat: repeat-y;

    &--inactive {
      min-height: 200px;

      .menu-section__content {
        display: none;
      }
    }

    .currency {
      svg {
        height: 0.8em;
        position: relative;
        top: 1px;
      }
    }

    &__hero {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-bottom: 8px solid #FF7901;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 450px;
      position: relative;
      overflow: hidden;

      &__image {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }


      @media screen and (max-width: 650px) {
        height: 450px;
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   background-image: url(../assets/images/icon-turtle.svg);
      //   background-size: contain;
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   opacity: 0.4;
      //   left: 50%;
      //   top: 50%;
      //   transform: translate(-50%, -50%);
      //   height: 200px;
      //   width: 200px;
      // }

      &--orange {
        border-bottom: 8px solid #FF7901;
      }

      &--blue {
        border-bottom: 8px solid #01C5B5;
      }
    }

    &__title {
      z-index: 1;
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 2.5em;
      line-height: 1em;
      text-shadow: 4px 3px 2px rgba(0, 0, 0, 0.45);
    }

    &__content {
      // display: grid;
      // grid-auto-columns: minmax(300px, auto);
      // gap: 1em;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &_additional-info-card {
      table, tr, td {
        border: 0;
      }

      &-title {
        margin: 0;
        font-size: 2.2em;

        &--orange {
          color: #FF7901;
        }

        &--blue {
          color: #01C5B5;
        }
      }

      &-body {
        padding: 0 16px 0 16px;

        td {
          padding: 16px 0 0 0;
          border: 0;

          p {
            font-size: 1.2em;

            @media only screen and (max-width: 600px) {
              white-space: normal;
              font-size: 1em;
            }
          }
        }

        p {
          margin: 0.2em 0 0 0;
          font-size: 1em;
          font-weight: normal;
        }

        h1, h2, h3, h4, h5, h6 {
          margin: 0;
        }

        h1, h2, h3 {
          font-size: 1.5em;
        }

        &--orange {
          h1, h2, h3, h4, h5, h6, b {
            color: #FF7901;
          }

          .currency {
            svg {
              fill: #FF7901;
            }
          }

          hr {
            outline: none;
            background: transparent;
            border: 2px solid #FF7901;
            margin-top: 16px;
          }
        }

        &--blue {
          h1, h2, h3, h4, h5, h6, b {
            color: #01C5B5;
          }

          .currency {
            svg {
              fill: #01C5B5;
            }
          }

          hr {
            outline: none;
            background: transparent;
            border: 2px solid #01C5B5;
            margin-top: 16px;
          }
        }
      }
    }

    &__additional-info-card {
      padding: 25px;
      background: #F7F4C8;
      text-align: center;
      flex-basis: 100%;
      max-width: 100vw;
      margin-bottom: 1em;


      table {
        border: 0;

        tbody > tr:only-child {
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          flex-wrap: wrap;

          td {
            white-space: nowrap;
            margin: 0 20px;
            border: 0;
          }
        }
      }

      blockquote {
        margin: 0;
        display: flex;
        justify-content: space-around;
      }
    }
  }
</style>