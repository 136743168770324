<template>
  <div class="combo-selector">
    <div class="container">
      <div class="combo-selector__input-select">
        <select name="restaurants" id="restaurants" @change="changeRestaurant" v-model="selectedRestaurant.fields.slug">
          <option value="" hidden>Choose a restaurant</option>
          <option v-for="restaurant in restaurantList" v-bind:key="restaurant.fields.slug" :value="restaurant.fields.slug">{{restaurant.fields.title}}</option>
        </select>
      </div>
      <div class="combo-selector__input-select">
        <select name="order" id="order" @change="changeOrder">
          <option value="">All</option>
          <option value="brunch">Bottomless brunch</option>
          <option value="drinks">Just drinks</option>
          <option value="dinner">Dinner</option>
          <option value="lunch">Brunch & Lunch</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComboSelector',
  computed: {
    restaurantList () {
      return this.$store.state.restaurants
    },
    selectedRestaurant() {
      return this.$store.state.selectedRestaurant
    },
    order() {
      return this.$route.query.order;
    },
    allergenGuide() {
      return this.$store.state.allergenGuide; 
    }
  },
  mounted () {
    if (this.order) {
      this.$el.querySelector(`select#order`).value = this.order;
    }
  }, 
  methods: {
    changeRestaurant (e) {
      let [val] = this.restaurantList.filter(el => {
        return el.fields.slug == e.target.value;
      });
      this.$store.commit('changeRestaurant', val);
      this.$router.push({ path: `/${e.target.value}`, params: {order: this.order}});
      this.$router.go();
    },
    changeOrder (e) {
      this.$router.replace({name: "Menu", query: {...this.$route.query, order: e.target.value}});
    }
  },
  props: {
    menu: {
      type: Object
    }
  } 
}
</script>

<style lang="scss">
  .combo-selector {
    border-top: 6px solid #FFD700;
    background: #019AD4;
    background-image: url(../assets/images/textures/tb-blue-texture-1.png);
    background-size: 50% auto;
    background-repeat: repeat;

    .container {
      padding-bottom: 24px;
    }

    &__input-select {
      margin: 0 auto 25px;
      position: relative;
      max-width: 450px;

      select {
        width: 100%;
        border: 4px solid #01C5B5;
        margin: 0;
        padding: 0.5rem 2.25rem 0.5rem 1rem;
        font-family: "cubano",sans-serif;
        font-size: 1em;
        line-height: 1.5;
        color: #01C5B5;
        background-color: #fff;
        border-radius: 0.25rem;
        cursor: pointer;
        outline: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 50px;
        height: 50px;
        background: #01C5B5 url(../assets/images/arrow.svg) center no-repeat;
        background-size: 50%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        pointer-events: none;
      }
    }

    &__pdf-selector {
      text-align: center;
    }

    &__link {
      display: inline-block;
      margin: 15px;
      text-decoration: none;
      border-bottom: 2px solid #67B2E9;
      padding: 10px 0; 
      text-transform: uppercase;
      color: #fff;

      &--disabled {
        opacity: 0.7;
        pointer-events: none;
      }
    }

    
  }
</style>