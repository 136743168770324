<template>
  <div class="Menu">
    <!-- <Header /> -->
    <ComboSelector :menu="menu" />
    <FilterSection />
    <SecondaryNav :items="[...menu.fields.sections]" />
    <div class="Menu__sections-wrapper">
      <MenuSection
        v-for="(section, index) in menu.fields.sections"
        v-bind:key="section.sys.id"
        :id="getIdFromTitle(section.fields.displayTitle)"
        :section="section"
        :index="index"
        :style="{
          'order': getOrder(section),
        }"
      />
    </div>

    <div class="Menu__popup">
      <MenuPopup/>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import MenuSection from "@/components/MenuSection.vue";
import ComboSelector from "../components/ComboSelector.vue";
import FilterSection from "../components/FilterSection.vue";
import SecondaryNav from "../components/SecondaryNav.vue";
import MenuPopup from "../components/MenuPopup";
// import Header from "../components/Header";
// import Footer from "../components/Footer";

export default {
  name: "Menu",
  components: {
    MenuSection,
    FilterSection,
    ComboSelector,
    SecondaryNav,
    MenuPopup,
    // Header,
    // Footer
  },
  data () {
    return {
      orderMap: this.$store.state.orderMap
    };
  },
  methods: {
    //get main menu by default, get menu by slug if specified
    getMenu() {
      let [menu] = this.$store.state.selectedRestaurant.fields.tierMenus.filter(
        (el) => {
          return this.$route.query.menu
            ? el.fields.slug == this.$route.query.menu
            : el.fields.displayTitle.toLowerCase() == "main menu";
        }
      );
      return menu;
    },
    filterMenuSections(menu) {
      // this is to filter out unpublished menus, since they do not have the fields property
      menu.fields.sections = menu.fields.sections.filter((el) => {
        return el.fields;
      });

      return menu;
    },
    getIdFromTitle(title) {
      return title.toLowerCase().replaceAll(" ", "").replaceAll("&", "");
    },
    getOrder(section) {
      let query = this.$route.query.order;

      if (!query) return 0;

      return section.fields[this.orderMap[query]];
    },
  },
  created() {
    let menu = this.getMenu();
    this.$store.commit("setSelectedMenu", this.filterMenuSections(menu));
    this.menu = this.$store.state.selectedMenu;
  },
  mounted() {
      
  }
};
</script>

<style lang="scss">
.Menu__sections-wrapper {
  display: flex;
  flex-direction: column;
}

.Menu__popup {
  z-index: 10;
}
</style>