<template >
  <div v-show="showInitial" v-if="show" :class="{
    'menu-item' : true,
    'menu-item--new' : item.fields.isNew || item.fields.label == 'New',
    'menu-item--new-recipe' : item.fields.isNewRecipie || item.fields.label == 'New recipe',
    'menu-item--starter' : item.fields.label == 'Starter',
    'menu-item--pudding' : item.fields.label == 'Pudding',
    'menu-item--main' : item.fields.label == 'Main',
    'menu-item--free' : item.fields.label == 'Free',
  }">
      <!-- <img :src="image" alt="" v-if="image" class="menu-item__image"> -->
      <div class="menu-item__content">

        <img v-if="item.fields.isNew || item.fields.label == 'New'" class="menu-item__new" :src="getImgUrl(`images/new--${theme}.svg`)" alt="new">
        <img v-if="item.fields.isNewRecipie || item.fields.label == 'New recipe'" class="menu-item__new" :src="getImgUrl(`images/new-recipe--${theme}.svg`)" alt="new-recipe">

        <img v-if="item.fields.label == 'Starter'" class="menu-item__new" :src="getImgUrl(`images/starter.svg`)" alt="Starter">
        <img v-if="item.fields.label == 'Pudding'" class="menu-item__new" :src="getImgUrl(`images/pudding.svg`)" alt="Pudding">
        <img v-if="item.fields.label == 'Main'" class="menu-item__new" :src="getImgUrl(`images/main.svg`)" alt="Main">
        <img v-if="item.fields.label == 'Free'" class="menu-item__new" :src="getImgUrl(`images/free.svg`)" alt="Free">
        

        <h3 class="menu-item__title">{{item.fields.title}}</h3>
        <div class="menu-item__content__detail">
          <p class="menu-item__price" v-show="price">
            <span class="menu-item__price-currency">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 702.44 897.56"><path d="M630.15,658.29H342.49a33.58,33.58,0,0,1-33.58-33.59V598.28a19.73,19.73,0,0,1,19.73-19.74H499.13c22.4,0,33.59-12.32,33.59-33.59V440.46a33.57,33.57,0,0,0-33.58-33.58H328.64a19.74,19.74,0,0,1-19.74-19.74V348.79c0-52.7,5.6-109.52,88.45-109.52,60.46,0,80.61,29.11,86.2,78.37,3.36,23.51,21.28,39.18,44.79,34.71l124.27-28c15.67-3.36,25.75-15.68,24.63-31.35-4.48-86.2-41.43-246.3-279.89-246.3-265.34,0-281,198.16-281,272.05v68.38A19.74,19.74,0,0,1,96.6,406.88H58.7c-21.27,0-33.58,11.19-33.58,33.58V545a33.54,33.54,0,0,0,33.53,33.54H96.6a19.73,19.73,0,0,1,19.74,19.73v219a33.59,33.59,0,0,0,33.59,33.59H630.15a33.58,33.58,0,0,0,33.59-33.58V691.87A33.58,33.58,0,0,0,630.15,658.29Z"/></svg>
            </span>{{ price }}</p>

          <div class="menu-item__content__detail__categories">
            <div v-for="tag in filteredTags" v-bind:key="tag" class="menu-item__content__detail__categories__category">
              <div class="menu-item__content__detail__categories__category__image" :class="['menu-item__content__detail__categories__category__image--'+tag.toLowerCase()]"></div>
              <div class="menu-item__content__detail__categories__category__title">{{ tag }}</div>
            </div>
          </div>
        </div>
        <div class="menu-item__separator" :class="['menu-item__separator--'+theme]"></div>
        <div class="menu-item__body" v-if="item.fields.displayInfoExcerpt">
          <RichTextRenderer class="menu-item__body" :document="item.fields.info" />
        </div>
        <div :class="['menu-item__button']" v-if="item.fields.moreInfoButtonStyle != 'None'">
          <button class="menu-item__button__button" @click="popupItem()" :class="['menu-item__button__button--'+theme, 'menu-item__button__button--'+item.fields.moreInfoButtonStyle]" v-html="item.fields.moreInfoButtonText? item.fields.moreInfoButtonText : 'DISCOVER MORE'"></button>
        </div>
      </div>

  </div>
</template>

<script>

import RichTextRenderer from 'contentful-rich-text-vue-renderer';

export default {
  name: 'MenuItem',
  components: {
    RichTextRenderer
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
    },
    theme: {
      type: String
    },
    showInitial: {
      type: Boolean
    }
  },
  data() {
    return {
      show: true,
    }
  },
  computed: {
    image()  {
      return this.item.fields.image? 'https:' + this.item.fields.image.fields.file.url : "";
    },
    flavours() {
      if (this.type == "cocktail") { 
        return this.item.fields.flavours.map(el => {
          return el.fields.title;
        });
      } 

      return false;
    },
    selectedAllergens() {
      return this.$store.state.selectedAllergens;
    },
    tags() {
      if (!this.item.metadata.tags) {
        return false;
      }

      return this.item.metadata.tags.map(tag => {
        return tag.sys.id;
      })
    },
    filteredTags() {
      return this.tags.filter(tag => {
        return ['vegan', 'vegetarian'].indexOf(tag) !== -1;
      })
    },
    allergens() {
      if (!this.item.fields.allergens) {
        return false;
      }

      return this.item.fields.allergens.map(allergen => {
        return allergen.fields.title;
      })
    },
    cocktailFilters () {
      return this.$store.state.cocktailFilters;
    },
    selectedTags () {
      return this.$store.state.selectedTags;
    },
    filtersUpdated () {
      return this.$store.state.filtersUpdated;
    },
    price () {
      let tier = this.$store.state.selectedMenu.fields.menuTier.toLowerCase();

      return this.item.fields[tier+"Price"]? this.item.fields[tier+"Price"] : false;
    }
  },
  mounted () {
    // dynamic watcher for categories, always exclude cocktails, since they are different
    
    // EDIT: for now cocktails should follow the traditional filtering same as other menu items
    // if (this.type != "cocktail") {

      // watching for changes in filtersUpdated state variable, because that is only updated after 
      // both selected tags and selected allergens is updated and filtering is dependent on both of these anyway
      this.$watch('filtersUpdated', function () {
        this.runFiltering();
      }); 
    // } 

    // add dynamic watcher for cocktail filters
    if (this.type == "cocktail") {
            
      this.$watch('cocktailFilters',
      function () {
        this.runFiltering();
      } );
    }
  },
  watch: {

  },
  methods: {
    popupItem: function () {
      this.$store.commit("setSelectedItem", this.item);
      this.$store.commit("setSelectedItemTheme", this.theme);
      this.$store.commit("setShowItemPopup", true);

      return true;
    },
    runFiltering: function () {
      // check tag condition 
      // need to clarify behaviour if item has no tags, but there are selected tags, for now its hiden by default
      let show = true;
      if (!this.selectedTags.length) {
        show = true;
      } else if (!this.tags.length) {
        show = false;
      } else {
        let intersection = this.tags.filter(el => { return this.selectedTags.includes(el) });
  
        show = Boolean(intersection.length);
      }

      // if its decided that item should be hidden here, return and don't check for allergens
      if (!show) {
        this.show = show;
        return;
      } 

      // continue with the allergen filtering
      // if item does not have allergens, or none allergens are selected always show
      if (!this.allergens.length || !this.selectedAllergens.length) {
        this.show = true;
        return;
      }

      let intersection = this.allergens.filter(el => { return this.selectedAllergens.includes(el) });

      // inverse condition, if there is any intersection then hide
      show = intersection.length == 0;

      // if item is hidden, do not proceed to cocktail filtering
      if (!show) {
        this.show = show;
        return;
      } 

      // cocktail filters
      // no cocktail filters means that its shown
      if (!this.cocktailFilters.length) {
        this.show = true;
        return;
      }

      // check for intersections in the cocktail filters state and then decice if item should be shown
      intersection = this.cocktailFilters.filter(element => this.flavours.includes(element)); 
      this.show = Boolean(intersection.length);
      return;
    },
    getImgUrl(img) {
      return require('../assets/'+img);
    }
  }
}
</script>

<style lang="scss">
  .menu-item {
    position: relative;
        
    @media screen and (min-width: 1250px) {
      width: calc(100% / 3 - 80px / 2); 
      margin: 20px;   
    }

     @media screen and (min-width: 800px) and (max-width: 1249px) {
      width: calc(100% / 2 - 40px); 
      margin: 20px;   
    }

    @media screen and (max-width: 799px) {
      margin: 20px 0;
      flex-basis: 100%;  
    }

    &__new {
      height: 40px;
      position: absolute;
      top: -15px;
      right: -20px;
    }

    &__image {
      max-width: 100%;
      height: auto;
      aspect-ratio: 16/5;
      object-fit: cover;
      object-position: center;
    }

    &__content {
      padding: 25px 25px 25px 25px;
      background: #F7F4C8;
      background-image: url(../assets/images/textures/tb-pale-texture-1.png);
      background-size: 100% auto;
      background-position: 70%;
      background-repeat: repeat-y;
      position: relative;
      
      &__detail {
        display: flex;
        justify-content: flex-start;

        &__price {

        }

        &__categories {
          display: flex;
          margin-bottom: 0.5em;
          flex-wrap: wrap;

          &__category {
            display: flex;
            font-family: "paralucent", sans-serif;
            margin-top: 4px;
            margin-right: 10px;

            &__image {
              width: 20px;
              height: 20px;
              background: black;
              border-radius: 50%;
              background-size: cover;

              &--vegan {
                background-image: url('../assets/images/vegan.png');
              }

              &--vegetarian {
                background-image: url('../assets/images/vegetarian.png');
              }
            }

            &__title {
              font-size: 0.9em;
              margin-left: 8px;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    &__title {
      margin: 0 0 0.5em;
      font-size: 1.4em;
    }

    &__price {
      margin: 0 0.5em 0.5em 0;
      font-family: "cubano",sans-serif;
      font-size: 1.17em;
      white-space: nowrap;

      &-currency {
        svg {

          height: .8em;
          width: auto;

          path {
            fill: #2f465f;
          }
        }
      }
    }

    &__separator {
      border-width: 0;
      border-bottom-width: 3px;
      border-style: solid;
      border-color: #FF7901;
      margin-bottom: 1em; 

      &--orange {
        border-color: #FF7901;
      }

      &--blue {
        border-color: #01C6B5;
      }
    }

    &__body {
      p:not(:first-child) {
        display: none;
      }

      font-family: sans-serif;
      font-weight: normal;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
              line-clamp: 3; 
      -webkit-box-orient: vertical;
      
      *:last-child {
        margin-bottom: 0;
      }

      *:first-child {
        margin-top: 0;
      }

      margin-bottom: 1em;

    }

    &__button {
      display: block;
      width: 100%;

      &__button {
        display: block;
        margin: 0 auto 0 auto;
        font-size: 1.17em;
        font-family: "cubano", sans-serif;
        border: none;
        outline: none;
        padding: 8px 24px;
        color: white;
        background: #FF7901;
        cursor: pointer;
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 90%;
        white-space: nowrap;

        &--orange {
          background: #FF7901;
        }

        &--blue {
          background: #01C6B5;
        }

        &--Link {
          position: static;
          background: none;
          color: #2f465f;
          transform: none;
          padding: 0 0 5px 0;
          border-bottom: 2px solid #118DD7;
        }

        &--Small-link {
          position: static;
          background: none;
          color: #2f465f;
          transform: none;
          text-transform: none;
          font-family: "paralucent",sans-serif;
          font-weight: normal;
          padding: 0 0 2px 0;
          font-size: 0.9em;
          border-bottom: 1px solid #118DD7;
        }
      }
    }
  }
</style>