<template>
  <div class="filter-section">
    <div class="filter-section__popup-wrapper" v-if="popupShow">
      <div class="filter-section__popup-overlay" @click="ClosePopup()"></div>
      <div class="filter-section__popup-body">
        <button
          type="button"
          title="close"
          @click="ClosePopup()"
          class="filter-section__popup-close-button"
        >
          <i class="fa fa-times"></i>
        </button>
        <h2 class="filter-section__popup-title">Dietary filters</h2>

        <a href="#" class="filter-section__popup-clear-filters" v-if="selectedFilterCount" @click="clearFilters">Clear filters</a>

        <h3 class="filter-section__popup-subtitle">
          Only show me stuff that's
        </h3>
        <div class="filter-section__popup-button-grid">
          <div
            v-for="tag in tags"
            v-bind:key="tag.id"
            :class="{
              'filter-section__popup-button': true,
              'filter-section__popup-button--none':
                $store.state.selectedRestaurant.fields.title.toLowerCase() !=
                  'blackburn' && tag.name.toLowerCase() == 'halal',
            }"
          >
            <label class="filter-section__popup-button-label">
              <input
                type="checkbox"
                class="filter-section__popup-button-input"
                :name="tag.id"
                :value="tag.id"
                v-model="selectedTags"
              />
              <span class="filter-section__popup-button-text">
                {{ tag.name }}
              </span>
            </label>
          </div>
        </div>

        <h3 class="filter-section__popup-subtitle">
          Exclude anything that contains
        </h3>
        <div class="filter-section__popup-button-grid">
          <div
            class="filter-section__popup-button"
            v-for="allergen in allergens"
            v-bind:key="allergen"
          >
            <label class="filter-section__popup-button-label">
              <input
                type="checkbox"
                class="filter-section__popup-button-input"
                :name="allergen"
                :value="allergen"
                v-model="selectedAllergens"
              />
              <span class="filter-section__popup-button-text">
                {{ allergen }}
              </span>
            </label>
          </div>
        </div>

        <p class="filter-section__popup-additional-info">
          We must tell you our food is prepared in kitchens where allergenic
          ingredients may be present so we can't guarantee that anything is 100%
          free of allergens at all times. If you have any food allergies,
          intolerances or sensitivities please speak to one of the team.
        </p>

        <button
          type="button"
          class="filter-section__popup-apply-button"
          @click="ApplyFilters"
        >
          Apply filters
        </button>

        <a
          :href="allergenGuide"
          class="filter-section__popup-link"
          target="_blank"
          rel="noopener noreferrer"
          >Download allergens guide</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterSection",
  props: {},
  data: function () {
    return {
      selectedTags: [],
      selectedAllergens: [],
    };
  },
  components: {},
  computed: {
    tags() {
      return this.$store.state.tags;
    },
    globalSelectedTags () {
      return this.$store.state.selectedTags;
    },
    allergens() {
      return this.$store.state.allergenList;
    },
    allergenGuide() {
      return this.$store.state.allergenGuide;
    },
    popupShow() {
      return this.$store.state.showDietaryFilters;
    },
    selectedFilterCount() {
      return this.$store.state.selectedFilterCount;
    },
  },
  created() {},
  mounted() {
    if (this.globalSelectedTags) {
      this.selectedTags = this.globalSelectedTags;
    }

  },
  watch: {
    'popupShow': function () {
        window.parent.postMessage({popup: this.popupShow}, '*');
    },
    'globalSelectedTags': function () {
      this.selectedTags = this.globalSelectedTags;
    }
  },
  methods: {
    ClosePopup: function () {
      this.$store.commit("setShowDietaryFilters", false);
    },
    ApplyFilters: async function () {
      // dispatch store action to commit filters
      this.$store.dispatch("applyMenuItemFilters", [
        this.selectedTags,
        this.selectedAllergens,
      ]);
      //close popup
      this.ClosePopup();
      this.ScrollToTop();
    },
    ScrollToTop: function () {
      window.scrollTo(0, document.querySelector('#app').offsetTop + 100);
    },
    clearFilters (e) {
      e.preventDefault();
      this.selectedTags = this.selectedAllergens = [];
      this.ApplyFilters();
    } 
  },
};
</script>

<style lang="scss">
.filter-section {
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__popup {
    &-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }

    &-body {
      text-align: center;
      color: #16334d;
      max-width: unquote("min(90%, 600px)");
      max-height: 90vh;
      padding: 30px 20px 40px;
      overflow: auto;
      position: relative;
      background: #1598db;
      background: #f7f4c8;
      background-image: url(../assets/images/textures/tb-pale-blue-texture-1.png);
      background-size: 100% auto;
      background-repeat: repeat-y;

      @media screen and (max-width: 380px) {
        padding-top: 60px;
      }
    }

    &-clear-filters {
      margin-top: 1em;
      display: inline-block;
      font-family: "paralucent", sans-serif;
      font-weight: 300;
      color: #1C445F;
      white-space: nowrap;
      cursor: pointer;
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.8);
    }

    &-title,
    &-subtitle {
      text-align: center;
      color: #16334d;
    }

    &-subtitle {
      font-size: 1.5em;
      font-weight: 100;
      margin: 1.25em 0 0.5em;

      @media screen and (max-width: 650px) {
        font-size: 1.25em;
      }
    }

    &-title {
      font-size: 2em;
      margin-top: 0;

      @media screen and (max-width: 650px) {
        font-size: 1.5em;
      }
    }

    &-close-button {
      position: absolute;
      right: 10px;
      top: 10px;
      background: 0;
      border: 0;
      color: #16334d;
      font-weight: bold;
      font-size: 1.75em;
      cursor: pointer;
    }

    &-button {
      margin: 5px;
      flex: 0 0 calc(100% / 3 - 10px);
      cursor: pointer;

      &--none {
          display: none;
      }

      &-grid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 1.5em;
      }

      &-input {
        display: none;
      }

      &-input:checked + &-text {
        background: #16334d;
        color: #fff;
      }

      &-text {
        display: block;
        background: #fff;
        color: #16334d;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "paralucent", sans-serif;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1em;
        line-height: 1;
        min-height: 2em;
        box-sizing: initial;

        @media screen and (max-width: 650px) {
          font-size: 0.75em;
          padding: 5px 10px;
        }
      }
    }

    &-apply-button {
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      border: 0;
      padding: 20px;
      background: #01c5b5;
      width: 100%;
      margin: 1em 0;
      font-size: 1.25em;
      font-weight: 100;
      font-family: "cubano", sans-serif;
      cursor: pointer;

      @media screen and (max-width: 650px) {
        font-size: 1em;
        padding: 15px;
      }
    }

    &-show-button {
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      border: 0;
      padding: 20px;
      background: #01c5b5;
      width: 100%;
      max-width: 350px;
      margin: 1em 0;
      font-size: 1.25em;
      font-weight: 100;
      font-family: "cubano", sans-serif;
      position: relative;
      margin: -35px auto 0;
      display: block;
    }

    &-link {
      text-decoration: none;
      color: #16334d;
      display: inline-block;
      padding: 10px 0;
      font-size: 1.2em;
      border-bottom: 2px solid #01c5b5;
      @media screen and (max-width: 650px) {
        font-size: 1em;
      }
    }
  }
}
</style>