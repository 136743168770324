<template>
  <div class="secondary-nav">
    <div class="secondary-nav__filter" @click="showDietaryFilters()" id="openFilterButton">
      <span class="secondary-nav__filter__blip" v-if="selectedFilterCount">{{ selectedFilterCount }}</span> 
      <img class="secondary-nav__filter__icon" src="../assets/images/icon-filter.svg" alt="filter">
    </div>
    <div class="secondary-nav__links">
      <span
          :data-item="item.fields.displayTitle.toLowerCase().replaceAll(' ', '').replaceAll('&', '')"
          v-for="item in items"
          v-bind:key="item.sys.id"
          @click="scrollTo"
          class="secondary-nav__link">{{ item.fields.displayTitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondaryNav',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      sectionObserver: null,
    }
  },
  computed: {
    orderMap () {
      return this.$store.state.orderMap;
    },
    menuOrder () {
      return this.$store.state.selectedOrder;
    },
     selectedFilterCount() {
      return this.$store.state.selectedFilterCount;
    },
  },
  mounted () {
    this.sortItems();

    let nav = this.$el.querySelector('.secondary-nav__links');

    let pos = { top: 0, left: 0, x: 0, y: 0 };

    const mouseDownHandler = function (e) {
        nav.style.cursor = 'grabbing';
        nav.style.userSelect = 'none';

        pos = {
            left: nav.scrollLeft,
            top: nav.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        };

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        nav.scrollTop = pos.top - dy;
        nav.scrollLeft = pos.left - dx;
    };

    const mouseUpHandler = function () {
        nav.style.cursor = 'grab';
        nav.style.removeProperty('user-select');

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    // Attach the handler
    nav.addEventListener('mousedown', mouseDownHandler);

    this.$nextTick(() => {
      this.sortItems();
      this.$forceUpdate();
      this.observeSections();
    });
  },
  methods: {
    showDietaryFilters() {
      this.$store.commit('setShowDietaryFilters', true);
    },
    getOrder(section) {
      let query = this.$route.query.order;

      if (!query) return false;

      return section.fields[this.orderMap[query]];
    },
    sortItems() {
      // this looks a bit sketchy, but not too bad.
      // its a bit weird passing more variables into the sort function,
      // that is why sort function is a function that takes needed variables,
      // and returns another function that does the actual sorting
      let orderMap = this.orderMap;
      let selectedOrder = this.menuOrder;
      let sortFunction = (map, order) => {
        return (a,b) => {
          return (a.fields[map[order]] > b.fields[map[order]]) ? 1 : ((b.fields[map[order]] > a.fields[map[order]]) ? -1 : 0);
      }}
      this.items = this.items.sort(sortFunction(orderMap, selectedOrder));
    },
    observeSections() {
      // function to tag secondary nav items as active
      try {
        this.sectionObserver.disconnect()
      } catch (error) {
        () => {
          return error;
        }
      }

      const options = {
        rootMargin: '0px 0px',
        threshold: 0.1
      }
      this.sectionObserver = new IntersectionObserver(this.sectionObserverHandler, options)
    
      // Observe each section
      const sections = document.querySelectorAll('.menu-section')
      sections.forEach(section => {
        this.sectionObserver.observe(section)
      })
    },
    sectionObserverHandler (entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id
          let link = this.$el.querySelector(`.secondary-nav__link[data-item="${sectionId}"]`);
          let activeLink = this.$el.querySelector(`.secondary-nav__link--active`);
          if (link && link != activeLink) {
            if (activeLink)
              activeLink.classList.remove('secondary-nav__link--active');
            
            link.classList.add('secondary-nav__link--active');

            // handle scrolling to the active item
            link.closest('.secondary-nav__links').scrollLeft = link.offsetLeft - 75;
          } 
        }
      }
    },
    scrollTo (e) {
      let target = document.querySelector(`.menu-section[id="${e.target.getAttribute('data-item')}"]`);

      let inactive = document.querySelectorAll(`.menu-section--inactive`);
      if (inactive.length) 
        inactive.forEach(el => el.classList.remove('menu-section--inactive'));
  

      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: target.offsetTop - 100
      });
    }
  },
  watch: {
    menuOrder: function () {
      this.sortItems();
    }
  }
}
</script>


<style lang="scss">
  .secondary-nav {
    display: flex;
    align-items: center;
    margin: 0;
    position: sticky;
    top: 0;
    z-index: 9;
    background: #AFD7F1;
    background-image: url(../assets/images/textures/tb-pale-blue-texture-1.png);
    background-size: 50% auto;
    background-repeat: repeat;

    &__filter {
      display: block;
      width: 70px;
      height: 70px;
      background: #01C5B5;
      cursor: pointer;
      flex-shrink: 0;
      position: relative;

      &__blip {
        position: absolute;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: 5px;
        right: 5px;
        background: #EF8525;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      }

      &__icon {
        display: block;
        margin: 22px auto 0 auto;
      }
    }

    &__links {
      padding: 1.1em 0;
      overflow-x: auto;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    &__links::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    &__link {
      padding: 0 15px;
      text-decoration: none;
      font-family: "paralucent", sans-serif;
      font-weight: 300;
      color: #1C445F;
      white-space: nowrap;
      cursor: pointer;

      &:not( [style='display: none;'] ) {
        border-left: 2px solid #1C445F;
      }

      &:first-child
      // ,
      // &[style*='display: none'] ~ &:not( [style*='display: none'] ) 
      {
        border-left: 0;
      }

      &--active {
        font-weight: bold;
      }
    }
  }
</style>
