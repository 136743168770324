<template >
  <div v-if="show" :class="{'menu-popup': true, 'menu-popup--no-image': !image }">
    <div :class="{'menu-popup__overlay': true, }" @click="closePopup()"></div>
    <div class="menu-popup__content">
      <span class="menu-popup__content__close" @click="closePopup()"><i class="fa fa-times"></i></span>
      <div class="menu-popup__content-wrapper">
        <div class="menu-popup__content__image" v-if="image !== ''">
          <img class="menu-popup__content__image__img" :src="image" :alt="item.fields.title">
        </div>

        <div class="menu-popup__content__container">
          <span class="menu-popup__content__label">
            <img v-if="item.fields.isNew" class="menu-item__new" :src="getImgUrl(`images/new--${theme}.svg`)" alt="new">
            <img v-if="item.fields.isNewRecipie" class="menu-item__new" :src="getImgUrl(`images/new-recipe--${theme}.svg`)" alt="new-recipe">
          </span>

          <span class="menu-popup__content__title menu-item__title">{{ item.fields.title }}</span>

          <div class="menu-popup__content__detail">
            <p v-if="price" class="menu-popup__content__detail__price menu-item__price"><span class="menu-item__price-currency">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 702.44 897.56"><path d="M630.15,658.29H342.49a33.58,33.58,0,0,1-33.58-33.59V598.28a19.73,19.73,0,0,1,19.73-19.74H499.13c22.4,0,33.59-12.32,33.59-33.59V440.46a33.57,33.57,0,0,0-33.58-33.58H328.64a19.74,19.74,0,0,1-19.74-19.74V348.79c0-52.7,5.6-109.52,88.45-109.52,60.46,0,80.61,29.11,86.2,78.37,3.36,23.51,21.28,39.18,44.79,34.71l124.27-28c15.67-3.36,25.75-15.68,24.63-31.35-4.48-86.2-41.43-246.3-279.89-246.3-265.34,0-281,198.16-281,272.05v68.38A19.74,19.74,0,0,1,96.6,406.88H58.7c-21.27,0-33.58,11.19-33.58,33.58V545a33.54,33.54,0,0,0,33.53,33.54H96.6a19.73,19.73,0,0,1,19.74,19.73v219a33.59,33.59,0,0,0,33.59,33.59H630.15a33.58,33.58,0,0,0,33.59-33.58V691.87A33.58,33.58,0,0,0,630.15,658.29Z"/></svg>
            </span>{{ price }}</p>
            <div class="menu-popup__content__detail__categories">
              <div v-for="tag in tags" v-bind:key="tag" class="menu-popup__content__detail__categories__category">
                <div class="menu-popup__content__detail__categories__category__image" :class="['menu-popup__content__detail__categories__category__image--'+tag.toLowerCase()]"></div>
                <div class="menu-popup__content__detail__categories__category__title">{{ tag }}</div>
              </div>
            </div>
          </div>

          <div class="menu-item__separator" :class="['menu-item__separator--'+theme]"></div>

          <div class="menu-popup__body" v-if="item.fields.info" >
            <RichTextRenderer class="menu-popup__body" :document="item.fields.info" />
          </div>

          <div class="menu-popup__content__allergens" v-if="item.fields.allergens || item.fields.calories" :class="['menu-popup__content__allergens--'+theme]">
            <span class="menu-popup__content__allergens__title" >{{item.fields.allergens ? "Allergenic ingredients in this dish" : "Caloric information for this dish"}}</span>
            <div class="menu-popup__content__allergens__list">
              <span class="menu-popup__content__allergens__list__item" v-for="allergen in item.fields.allergens" v-bind:key="allergen.sys.id">{{ allergen.fields.title }}</span>
            </div>
          
            
            <span class="menu-popup__content__allergens__calories" v-if="item.fields.calories">KCAL {{ item.fields.calories }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RichTextRenderer from 'contentful-rich-text-vue-renderer';

export default {
  name: 'MenuPopup',
  components: {
    RichTextRenderer
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    image()  {
      return this.item.fields.image? 'https:' + this.item.fields.image.fields.file.url : "";
    },
    show () {
      return this.$store.state.showItemPopup;
    },
    item () {
      return this.$store.state.selectedItem;
    },
    theme () {
      return this.$store.state.selectedItemTheme
    },
    tags() {
      if (!this.item.metadata.tags) {
        return false;
      }

      return this.item.metadata.tags.map(tag => {
        return tag.sys.id;
      }).filter(tag => {
        return ['vegan', 'vegetarian'].indexOf(tag) !== -1;
      });
    },
    price () {
      let tier = this.$store.state.selectedMenu.fields.menuTier.toLowerCase();

      return this.item.fields[tier+"Price"]? this.item.fields[tier+"Price"] : false;
    }
  },
  mounted () {

  },
  watch: {
    'show': function () {
      window.parent.postMessage({popup: this.show}, '*');
    }
  },
  methods: {
    closePopup: function () {
      this.$store.commit("setSelectedItem", false);
      this.$store.commit("setShowItemPopup", false);
    },
    getImgUrl(img) {
      return require('../assets/'+img);
    }
  }
}
</script>

<style lang="scss">
  .menu-popup {
    $self: &;

    &_body {
      font-family: sans-serif;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 1em;
    }

    &--no-image {
      #{ $self }__content__title {
        padding-right: 40px;
      }

      #{ $self }__content__close {
        color: #2f465f;
      }

    }

    &__overlay {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    &__content {
      max-height: 90vh;
      display: block;
      position: fixed;
      top: 32px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 70%;
      max-width: 600px;
      height: auto;
      z-index: 10;

      @media screen and (max-width: 660px) {
        width: 90vw;
      }

      &-wrapper {
        max-height: 90vh;
        overflow-y: auto; 
        overflow-x: hidden;
      }

      &__image {
        width: 100%;
        max-height: 280px;
        overflow: hidden;

        &__img {
          width: 100%;
        }
      }

      &__container {
        padding: 32px;
        background-color: #F8F4D1;
        background: #F7F4C8;
        background-image: url(../assets/images/textures/tb-pale-texture-1.png);
        background-size: 100% auto;
        background-position: 70%;
        background-repeat: repeat-y;
      }

      &__detail {
        display: flex;
        justify-content: flex-start;

        &__price {
          white-space: nowrap;
        }

        &__categories {
          margin-left: 32px;
          display: flex;
          margin-bottom: 0.5em;
          flex-wrap: wrap;

          &__category {
            display: flex;
            font-family: "paralucent", sans-serif;
            margin-top: 4px;
            margin-right: 10px;

            &__image {
              width: 20px;
              height: 20px;
              background: black;
              border-radius: 50%;
              background-size: cover;

              &--vegan {
                background-image: url('../assets/images/vegan.png');
              }

              &--vegetarian {
                background-image: url('../assets/images/vegetarian.png');
              }
            }

            &__title {
              font-size: 0.9em;
              margin-left: 8px;
              text-transform: capitalize;
            }
          }
        }
      }
      
      &__close {
        position: absolute;
        right: 32px;
        top: 30px;
        font-size: 1.5em; 
        cursor: pointer;
        color: #fff;
      }

      &__title {
        display: block;
        margin: 12px 0 12px 0;
      }

      &__allergens {
        display: block;
        width: auto;
        height: auto;
        margin-top: 24px;
        outline: 3px solid #FF7901;
        padding: 18px;
        font-family: "paralucent", sans-serif;

        &--orange {
          outline: 3px solid #FF7901;
        }

        &--blue {
          outline: 3px solid #01C6B5;
        }

        &__title {
          display: block;
          font-weight: bold;
          font-size: 0.8em;
        }

        &__list {
          display: block;
          margin-top: 12px;

          &__item {
            display: block;
          }
        }

        &__calories {
          display: block;
          margin-top: 32px;
        }
      }
    }
  }
</style>